
.purple-dude{
	position: absolute;
	top: calc(35%);
	left: 0;
	z-index: -1;
	height: 500px;
	width: 340px;
}

.purple__dude--link{
	cursor: auto;
}

@media only screen and (min-width: 375px) and (max-width: 667px) {
	.purple-dude{
		position: absolute;
		transition: all 0.8s ease;
		top: calc(33%);
		height: 200px;
		width: 145px;
	}
}

@media only screen and (min-width: 667px) and (max-width: 825px) {
	.purple-dude{
		top: calc(32%);
		transition: all 0.8s ease;
		height: 350px;
		width: 260px;
	}
}

@media only screen and (min-width: 825px) and (max-width: 915px){
	.purple-dude{
		transition: all 0.8s ease;
		height: 300px;
		width: 230px;
		position: absolute;
	}
}

@media screen and (max-width: 375px) {
	.purple-dude{
		width: 130px;
		height: 180px;
		transition: all 0.8s ease;
		top: calc(32%);
	}
}