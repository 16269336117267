.snazzy-octo{
	position: absolute;
	top: calc(10%);
	left: 0;
	height: 300px;
	width: 300px;
}

.snazzy-octo--link{
	cursor: auto;
}

@media only screen and (min-width: 375px) and (max-width: 667px) {
	.snazzy-octo{
		position: absolute;
		top: 2%;
		height: 200px;
		width: 200px;
	}
}

@media (min-width:667px) and (max-width:1184px) {
	.snazzy-octo{
		transition: all 0.8s ease;
		position: absolute;
		top: 2%;
		height: 200px;
		width: 200px;
	}
}


@media only screen and (min-width: 667px) and (max-width: 825px) {

} 

@media screen and (max-width: 375px) {
	.snazzy-octo{
		width: 180px;
		height: 180px;
		top: 2%;
	}
}