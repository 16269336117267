@import url("https://use.typekit.net/ykv0mtm.css");

.homepage {
	min-height: 100vh;
	overflow: hidden !important;
}

.homepage h1 {
	color: snow;
}

.homepage__container {
	width: 100%;
	will-change: contents;
	min-height: 400px;
	opacity: 0;
	height: 90%;
	margin: 0 auto;
	z-index: 0;
	transform-style: preserve-3d;
	animation: fadeIn 0.6s forwards;
	animation-delay: 0.6s;
	display: grid;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	position: relative;
}

.homepage__container h1 {
	/* width: 32px; */
	font-size: 2.4rem; 
	margin: 0;
	padding: 5px;
	letter-spacing: 2px;
	font-family: ibm-plex-mono, sans-serif;
	font-style: normal;
	font-weight: 700;
	color: #dbdbdb;
}

.darken{
	transition: all 0.6s ease-in;
	filter: blur(4px);
}

@media screen and (max-width: 768px) {
	body {
		overflow-x: hidden !important;
		width: 100% !important;

	}

	html {
		overflow-x: hidden !important;
		width: 100% !important;
	}

	.homepage {
		overflow-x: hidden !important;
	}

	.homepage__container {
		overflow-x: hidden !important;
	}

}
