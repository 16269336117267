.languages {
	width: 70%;
	margin-bottom: 2em;
}

.languages__title--main {
	padding: 10px;
	color: #dbdbdb;
	font-weight: 700;
	font-size: 1.4em;
}

.languages__body--columns {
	display: flex;
	justify-content: flex-start;
	align-items: flex-start;
	align-content: flex-start;
}

.languages__body--column{
	color: #727474;
	font-size: 1.1rem;
	font-weight: 700;
	padding: 10px;
	margin-top: 10px;
	width: 30%;
	min-height: 12vh;
}

.languages__body--column span{
	color: #672ae9;
	font-style: italic;
}

.languages__body--column p{
	font-size: 1.2rem;
	font-weight: normal;
}

@media only screen and (min-width: 667px) and (max-width: 915px){
	.languages__body--columns{
		display: flex;
		flex-wrap: wrap;
	}

	.languages__body--column{
		width: 100%
	}

	.languages__body--column p{
		font-size: 1rem;
	}
}

@media only screen and (min-width: 375px) and (max-width: 667px){
	.languages {
		width: 90%;
	}

	.languages__body--columns{
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.languages__body--column{
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;

	}

	.languages__title--main{
		white-space: initial;
		font-size: 1.4rem;
		margin-top: 5px;
		text-align: center;
	}	
}

@media screen and (max-width: 375px) {
	.languages {
		width: 90%;
	}

	.languages__body--columns{
		display: flex;
		flex-direction: column;
	}

	.languages__body--column{
		font-size: 1rem;
		width: 100%;
	}
}