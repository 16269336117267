.about-introduction {
	overflow: hidden !important;
	margin-top: 300px;
	width: 70%;
	padding: 1.5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.about-introduction__title {
	color: snow;
	font-size: 3.5rem;
	font-weight: 700;
}

.aboutDot {
	height: 10px;
	display: inline-flex;
	width: 10px;
	border-radius: 50%;
	background-color: #dbdbdb;
	box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
	animation: pulse-white 2s infinite;
}

@keyframes pulse-white {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

.about-introduction__selected {
	color: #672ae9;
	font-size: 3.5rem;
	font-weight: 700;
}

.about__icons__wrapper {
	display: flex;
	align-items: center;
	align-content: center;
	justify-content: space-between;
	width: 60%;
	margin-top: 1.5em;
	padding-top: 1em;
	padding-bottom: 1em;
}

.icon-container {
	width: 40%;
	display: flex;
	justify-content: space-between;
}

.about__icons {
	color: #dbdbdb;
	margin-left: 10px;
	transition: all 0.7s ease-in;
	height: 20px;
}

.about__download {
	color: #727474;
	font-size: 1.1em;
	font-weight: 700;
}

.about__resume--button {
	border-radius: 0.9em;
	border: none;
	padding: 0.3em 0.6em;
	background-color: #672ae9;
	transition: all 0.6s ease-out;
}

.about__resume--button:hover {
	color: #dbdbdb;
}

.about__icons:hover, :focus, :active {
	color: #672ae9;
}

.about__brief {
	margin-top: 1.5rem;
	padding-top: 1em;
	padding-bottom: 1em;
	color: #672ae9;
	font-weight: 700;
	font-size: 1.2rem;
}

.about__resume--link{
	color: #000000;
	text-decoration: none;
	transition: all 0.6s ease-out;
}

.about__resume--link:hover {
	color: #dbdbdb;
}



@media only screen and (min-width: 915px) and (max-width: 1184px) {

	.about__icons__wrapper {
		width: 80%;
	}
}

@media only screen and (min-width: 667px) and (max-width: 915px) {

	.about-introduction__heading {
		text-align: center;
	}

	.about-introduction__title {
		font-size: 3rem;
		margin-left: 5px;
	}

	.about-introduction__selected {
		font-size: 3rem;
		display: block;
		margin: auto;
		width: auto;
		word-wrap: break-word;
	}

	.about__icons__wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.icon-container {
		width: 40%;
		margin-bottom: 2em;
	}

	.about__download {
		margin-top: 2em;
		width: auto;
	}

	.about__brief p {
		white-space: initial;
		font-size: 1.5rem;
		margin-top: 15px;
		text-align: center;
	}

}

@media only screen and (min-width: 375px) and (max-width: 667px) {

	.about-introduction {
		width: 85%;
	}

	.about-introduction__heading {
		text-align: center;
	}

	.about-introduction__title {
		font-size: 2.2rem;
		margin-left: 5px;
	}

	.about-introduction__selected {
		font-size: 2.1rem;
		display: block;
		margin: auto;
		width: auto;
		word-wrap: break-word;
	}

	.about__icons__wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.icon-container {
		width: 40%;
		margin-bottom: 2em;
	}

	.about__download {
		margin-top: 2em;
	}

	.about__brief p {
		white-space: initial;
		font-size: 1.1rem;
		margin-top: 15px;
		text-align: center;
	}


}

@media screen and (max-width: 375px) {
	.about-introduction {
		width: 90%;
	}

	.about-introduction__heading{
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.about-introduction__title {
		font-size: 2.2rem;
		width: 60%;
	}
	.aboutDot{
		height: 8px;
		display: inline-block !important;
		width: 8px;
		border-radius: 50%;
		background-color: #dbdbdb;
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
		animation: pulse-white 2s infinite;
	}

	.about-introduction__selected{
		font-size: 2rem;
		width: 240px;
		word-wrap: break-word;
	}

	.about__icons__wrapper{
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	.icon-container{
		width: 100%;
	}
	.about__download{
		display: flex;
		flex-direction: column;
		margin-top: 30px;
		width: 100%;
		text-align: center;
	}
	.about__resume--button{
		width: 70%;
		align-self: center;
		margin-top: 20px;
	}

	.about__brief p{
		font-size: 1rem;
		text-align: center;
	}
}