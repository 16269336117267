@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');

.projectItem {
	margin-top: 100px;
}

.projectItem__wraper {
	display: flex;
	align-items: center;
	font-family: ibm-plex-mono,sans-serif;
	font-weight: 700;
	font-style: normal;
}

.projectItem__box:hover{
	transition: all 0.5s ease-in-out;
	transform: translateY(-20px);
}

.projectItem__image {
	width: 250px;
	padding: 10px;
}

.projectItem__body {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-left: 5em;
}

.projectItem__title {
	color: #672ae9;
	font-weight: 700;
	font-size: 3.5rem;
}

.projectItem__description {
	color: #dbdbdb;
	font-size: 1.4rem;
	font-weight: 700;
	margin-top: 10px;
}

.projectItem__stack {
	color: #727474;
	font-size: 1.2rem;
	margin-top: 7px;
}

.stack__list {
	color: #672ae9;
	font-size: 1.2rem;
	font-weight: 700;
}

.projectItem__link {
	text-decoration: none;
	cursor: pointer;

}

.projectItem__title:hover {
	transition: all 0.4s ease;
	color: #727474;
}

.projectItem__link:hover {
	transition: all 1s ease-in-out;
}


@media (min-width:667px) and (max-width:1184px) {}

@media only screen and (min-width: 375px) and (max-width: 667px) {
	.projectItem__wraper {
		width: 95%;
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.projectItem__body {
		width: 100%;
		margin: auto;
	}

	.projectItem__title {
		font-size: 2.5rem;
		white-space: initial;
	}


	.projectItem__description {
		font-size: 1.2rem;
		white-space: initial;
	}

	.projectItem__stack {
		font-size: 1.2rem;
		white-space: initial;

	}
	.projectItem__title {
		font-size: 2.2rem;
		white-space: initial;
		text-align: center;
	}

	.projectItem__description {
		font-size: 1.2rem;
		white-space: initial;
		text-align: center;
	}

	.projectItem__stack{
		text-align: center;
	}


	.stack__list {
		font-size: 1.2rem;
		white-space: initial;
	}
}

@media screen and (max-width: 375px) {
	.projectItem__wraper {
		display: flex;
		flex-direction: column;
		align-content: center;
	}

	.projectItem__body {
		width: 100%;
		margin: auto;
	}

	.projectItem__body {
		width: 100%;
		margin-top: 5px;
	}

	.projectItem__title {
		font-size: 2.5rem;
		white-space: initial;
		text-align: center;
	}

	.projectItem__description {
		font-size: 1.2rem;
		white-space: initial;
		text-align: center;
	}

	.projectItem__stack{
		text-align: center;
	}

	.stack__list {
		font-size: 1.3rem;
		white-space: initial;
	}
}