@import url("https://use.typekit.net/ykv0mtm.css");

.header {
	display: flex;
	min-height: 8vh;
	align-items: center;
	justify-content: space-between;
	z-index: 100;
	top: -30px;
	left: 0;
	transition: all 1s ease-in-out;
}

.header__chosen--link {
	color: #672ae9;
	font-family: ibm-plex-mono, sans-serif !important;
	font-weight: 700;
	font-style: normal;
	margin-left: 2rem;
	text-align: left;
	min-width: 100px;
	font-size: 1.2rem;
	z-index: 20;
	transition: all 0.7s ease-in;
}

.header__navlinks {
	display: flex;
	margin-right: 1.8rem;
	transition: all 1s ease-out;
}

.header__link {
	color: #dbdbdb;
	font-family: ibm-plex-mono, sans-serif !important;
	font-weight: 700;
	font-style: normal;
	padding: 10px;
	text-decoration: none;
	font-size: 1.2rem;	
	transition: all 0.7s ease-in;
}

.header__link--logos {
	display: flex
}

.header__link:hover {
	transition: all 0.4s ease-in-out;
	color: #672ae9;
}

.header__link :active {
	color: #727474 !important;
}

.burgermenu {
	cursor: pointer;
	padding: 15px;
	display: none;
}

.burgermenu>div {
	width: 7px;
	height: 7px;
	background-color: #672ae9;
	margin-top: 5px;
	border-radius: 50%;
}

.scrolled {
	transition: all 0.7s ease-in;
	width: 100%;
	position: fixed;
	background-color: #0d1212;
	top: 0;
	left: 0;
}

.selectedButton{
	color: #727474
}

@media screen and (max-width: 1024px) {
	body {
		overflow-x: hidden !important;
	}

	.header__navlinks {
		width: 70%;
	}

}

/* For Desktop */
@media (min-width:769px) and (max-width:1024px) {
	body {
		overflow-x: hidden !important;
	}
}

/* For Tablet */
@media (min-device-width:769px) and (max-device-width:1024px) {
	body {
		overflow-x: hidden !important;
	}
}


@media screen and (max-width: 768px) {
	body {
		overflow-x: hidden;
		width: 100%;
		position: absolute;
	}

	.header__navlinks {
		position: absolute;
		right: 0px;
		height: 92vh;
		top: 8vh;
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		align-items: flex-end;
		justify-content: flex-end;
		margin-bottom: 5em;
		padding-bottom: 40px;
		transform: translateX(140%);
	}

	.header__link {
		font-size: 2em;
	}

	.burgermenu {
		display: block;
		margin-right: 1rem;

	}

	.burgermenu:hover .burgermenu__dot {
		transition: all 1.2s ease-in-out;
		background-color: #727474;
	}

}

/* Small mobile dimensions */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) {
	html, body {
		position: absolute;
		overflow-x: scroll !important;
		white-space: nowrap;
	}

}

.active {
	transition: all 0.8s ease-in-out;
	transform: translateX(0%);
	z-index: 1000;
}