@import url("https://use.typekit.net/ykv0mtm.css");

.contact {
	width: 100%;
	will-change: contents;
	opacity: 0;
	min-height: 100vh;
	margin: 0 auto;
	transform-style: preserve-3d;
	animation: fadeIn 0.6s forwards;
	animation-delay: 0.6s;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	position: relative;
	font-family: ibm-plex-mono, sans-serif;
	z-index: 0;
	margin-bottom: 10px;
}