@import url("https://use.typekit.net/ykv0mtm.css");

.introduction{
	width: 50%;
	margin-top: 300px;
}

.introduction__name{
	color: #dbdbdb;
	font-size: 4rem;
}

.introduction__heading{
	font-family: ibm-plex-mono, sans-serif;
	font-style: normal;
	font-weight: 700;
}

.introduction__dot{
	display: inline-flex;
	height: 10px;
	width: 10px;
	border-radius: 50%;
	background-color: #672ae9;
	margin-left: 3px;
	margin-right: 3px;
	box-shadow: 0 0 0 0 #672ae9;
	animation: pulse-purple 2s infinite;
}

@keyframes pulse-purple {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #672ae9;
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 22px rgba(255, 255, 255, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

.introduction__is--span {
	color: #672ae9;
	font-size: 4rem;
}

.introduction__h5 {
	color: #672ae9;
	font-weight: 700;
	margin-top: 10px;
	font-size: 2rem;
}

.introduction__dev--span {
	color: #727474;
	font-size: 2.2rem;
	font-weight: 700;
}

@media only screen and (min-width: 667px) and (max-width: 915px) {
	.introduction{
		width: 70%;
		text-align: center;
	}
}

@media screen and (max-width: 768px) {

	.introduction{
		width: 80%;
	}
	
	.introduction__name{
		font-size: 3rem;
	}

	.introduction__is--span {
		font-size: 3rem;
	}
}

@media only screen and (min-width: 375px) and (max-width: 667px) {
	
	.introduction{
		width: 90%;
		display: flex;
		flex-direction: column;
		align-items: center;
		align-content: center;
		text-align: center;
	}
	
	.introduction__name{
		font-size: 3rem;
	}

	.introduction__is--span {
		font-size: 3rem;
	}
	.introduction__dev--span {
		font-size: 1.3rem;
		width: 100%;
	}
}

@media screen and (max-width: 375px) {
	.introduction{
		width: 90%;
		display: flex;
		flex-direction: column;
		align-items: center;
		align-content: center;
		text-align: center;
	}

	.introduction__heading{
		text-align: center;
	}
	
	.introduction__name{
		font-size: 2.2rem;
	}

	.introduction__is--span {
		font-size: 2.2rem;
		text-align: center;
	}
	.introduction__dev--span {
		font-size: 1.3rem;
		width: 100%;
	}
}