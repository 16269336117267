
.floating-astronaut{
	width: 550px;
	margin-top: 40px;
	position: absolute;
	left: -500px;
	bottom: 20%;
	z-index: -2;
}

.floating-astronaut__link{
	cursor: auto;
}

@media only screen and (min-width: 375px) and (max-width: 667px) {
	.floating-astronaut{
		width: 200px;
		bottom: 21%;
	}
}

@media only screen and (min-width: 667px) and (max-width: 825px) {
	.floating-astronaut{
		width: 250px;
	}
}

@media screen and (max-width: 375px) {
	.floating-astronaut{
		width: 200px;
		bottom: 26%;
	}
}