.projectList {
	width: 60%;
	padding: 5px;
	margin-top: 5em;
	margin-bottom: 5em;
}

@media (min-width:667px) and (max-width:1184px) {
	.projectList {
		width: 80%;
	}
}

@media screen and (max-width: 768px) {
	.projectList {
		width: 100%;
	}

}

@media only screen and (min-width: 375px) and (max-width: 667px) {}

@media screen and (max-width: 375px) {}