@import url("https://use.typekit.net/ykv0mtm.css");

.about {
	overflow-x: hidden !important;
	width: 100%;
	will-change: contents;
	opacity: 0;
	min-height: 100vh;
	margin: 0 auto;
	transform-style: preserve-3d;
	animation: fadeIn 0.6s forwards;
	animation-delay: 0.6s;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	position: relative;
	font-family: ibm-plex-mono, sans-serif;
	z-index: 0;
}

.portrait{
	position: absolute;
	z-index: -5;
	width: 80%;
	right: -32%;
	opacity: 0.5;
}

@media only screen and (min-width: 915px) and (max-width: 1184px) {
	.portrait{
		position: absolute;
		z-index: -5;
		width: 100%;
		right: -42%;
		opacity: 0.5;
	}
	

}

@media only screen and (min-width: 667px) and (max-width: 915px) {
	.portrait{
		width: 100%;
		position: absolute;
		left: 0;
		opacity: 0.45;
	}


}

@media only screen and (min-width: 375px) and (max-width: 667px) {
	.portrait{
		width: 100%;
		position: absolute;
		left: 0;
		opacity: 0.7;
	}

}

@media screen and (max-width: 375px) {
	.portrait{
		width: 100%;
		position: absolute;
		left: 0;
		opacity: 0.7;
	}

}