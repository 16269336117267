
.quote {
	width: 50%;
	margin-top: 350px;
	font-family: ibm-plex-mono, sans-serif;
	font-weight: 700;
	font-style: normal;
	position: relative;
}

.quote h2{
	color: #672ae9 !important;
	font-weight: 700 !important;
}

.quote__comment{
	font-size: 1.2em;
	color: #727474;
}

.quote__body{
	color: #dbdbdb;
	font-weight: 700;
	font-size: 2em;
}

@media only screen and (min-width: 375px) and (max-width: 667px) {
	.quote {
		width: 90%;
	}
	.quote h2{
		font-size: 2rem;
	}
	.quote__body{
		white-space: initial;
		font-size: 1.5rem;
	}
}


@media only screen and (min-width: 667px) and (max-width: 825px) {
	.quote {
		width: 80%;
	}
}

@media screen and (max-width: 375px) {
	.quote {
		width: 90%;
	}
	.quote h2{
		font-size: 2rem;
	}
	.quote__body{
		white-space: initial;
		font-size: 1.5rem;
	}
}