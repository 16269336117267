.outro{
	color: snow;
	width: 50%;
	margin-top: 200px;
	font-family: ibm-plex-mono, sans-serif;
	font-weight: 700;
	font-style: normal;
	margin-bottom: 50px;
}

.outro__title{
	color: snow;
	font-size: 1.6em;
	font-weight: 700;
}

.outro__body{
	font-size: 4em;
	font-weight: 700;
}

.outro__projects{
	color: #672ae9;
}

.outro__link{
	text-decoration: none;
	color: #672ae9;
}

.outro__link:hover{
	transition: all 1.4s ease-out;
	color: #727474;
}

@media only screen and (min-width: 375px) and (max-width: 667px) {
	.outro {
		width: 90%;
	}

	.outro__title{
		white-space: initial;
		font-size: 1.5rem;
	}
	.outro__body{
		white-space: initial;
		font-size: 1.5rem;
	}
}

@media only screen and (min-width: 667px) and (max-width: 825px) {
	
	.outro__title{
		font-size: 1.5rem;
	}
	.outro__body{
		font-size: 3rem;
	}
}


@media screen and (max-width: 375px) {
	.outro {
		width: 90%;

	}

	.outro__title{
		white-space: initial;
		font-size: 1.6rem;
		text-align: center;
	}
	.outro__body{
		white-space: initial;
		font-size: 1.6rem;
		text-align: center;
	}
}