.loveLetter {
	width: 50%;
	margin-top: 300px;
	font-family: ibm-plex-mono, sans-serif;
	font-weight: 700;

	font-style: normal;
	font-size: 2.3rem;
}

.loveLetter__coding {
	color: #672ae9;
}

.loveLetter__cheese {
	color: #672ae9;
}

.loveLetter__line1 {
	color: #dbdbdb;
}

.loveLetter__line2 {
	color: #dbdbdb;
}

.loveLetter__line3 {
	color: #dbdbdb;
}


@media only screen and (min-width: 667px) and (max-width: 915px){
	.loveLetter {
		width: 60%
	}
}

@media screen and (max-width: 768px) {
	.loveLetter {
		width: 80%
	}
}

@media only screen and (min-width: 375px) and (max-width: 667px) {
	.loveLetter {
		width: 90%;
		font-size: 1rem;
	}

	.loveLetter__line1 {
		white-space: initial;
		font-size: 1.5rem;
	}

	.loveLetter__line2 {
		white-space: initial;
		font-size: 1.5rem;
		margin-top: 2em;
	}

	.loveLetter__line3 {
		white-space: initial;
		font-size: 1.5rem;
		margin-top: 2em;
	}

}

@media screen and (max-width: 375px) {
	.loveLetter {
		width: 90%;
		font-size: 1rem;
	}

	.loveLetter__line1 {
		white-space: initial;
		font-size: 1.5rem;
	}

	.loveLetter__line2 {
		white-space: initial;
		font-size: 1.5rem;
		margin-top: 2em;
	}

	.loveLetter__line3 {
		white-space: initial;
		font-size: 1.5rem;
		margin-top: 2em;
	}
}