.contact-introduction {
	overflow: hidden !important;
	margin-top: 200px;
	width: 50%;
	padding: 1.5rem;
	display: flex;
	flex-direction: column;
	justify-content: center;
}


.contact-introduction__title {
	color: snow;
	font-size: 3.5rem;
	font-weight: 700;
}

.contactDot {
	height: 10px;
	display: inline-flex;
	width: 10px;
	border-radius: 50%;
	background-color: #dbdbdb;
	box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
	animation: pulse-white 2s infinite;
}

@keyframes pulse-white {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
	}
}

.contact-introduction__selected {
	color: #672ae9;
	font-size: 3.5rem;
	font-weight: 700;
}

.contact__work {
	color: #727474;
	font-size: 1.6em;
	font-weight: 700;
}

@media only screen and (min-width: 1650px) and (max-width: 1900px) {
	/* .contact-introduction {
	} */
}

@media only screen and (min-width: 1184px) and (max-width: 1650px) {
	.contact-introduction {
		width: 60%;
	}

	.contact-introduction__title {
		font-size: 4rem;
		margin-left: 5px;
	}

	.contact-introduction__selected {
		font-size: 4rem;
	}
}


@media only screen and (min-width: 1184px) and (max-width: 1419px){
	.contact-introduction__title {
		font-size: 2.7rem;
		margin-left: 5px;
	}
	.contact-introduction__selected {
		font-size: 2.7rem;
	}
}

@media only screen and (min-width: 915px) and (max-width: 1184px) {
	.contact-introduction {
		width: 60%;
	}
	.contact-introduction__title {
		font-size: 2.5rem;
		margin-left: 5px;
	}
	.contact-introduction__selected {
		font-size: 2.5rem;
	}

}

@media only screen and (min-width: 915px) and (max-width: 1024px){
	.contact-introduction {
		text-align: center;
	}
}

@media only screen and (min-width: 667px) and (max-width: 915px) {
	.contact-introduction {
		width: 90%;
	}

	.contact-introduction__heading{
		text-align: center;
	}

	.contact-introduction__title {
		font-size: 3rem;
		margin-left: 5px;
	}

	.contact-introduction__selected {
		font-size: 3rem;
		display: block;
		margin: auto;
		width: auto;
		word-wrap: break-word;
	}

	.contact__work {
		white-space: initial;
		font-size: 2.4rem;
		margin-top: 15px;
		text-align: center;
	}

}

@media only screen and (min-width: 375px) and (max-width: 667px) {
	.contact-introduction {
		width: 90%;
	}

	.contact-introduction__heading{
		text-align: center;
	}

	.contact-introduction__title {
		font-size: 2.4rem;
		margin-left: 5px;
	}

	.contact-introduction__selected {
		font-size: 2.4rem;
		display: block;
		margin: auto;
		width: auto;
		word-wrap: break-word;
	}

	.contact__work {
		white-space: initial;
		font-size: 2rem;
		margin-top: 15px;
		text-align: center;
	}
}

@media screen and (max-width: 375px) {
	.contact-introduction {
		width: 90%;
	}

	.contact-introduction__heading{
		text-align: center;
	}

	.contactDot {
		height: 7px;
		display: inline-flex;
		width: 7px;
		border-radius: 50%;
		background-color: #dbdbdb;
		box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
		animation: pulse-white 2s infinite;
	}
	.contact-introduction__title {
		font-size: 1.8rem;
		margin-left: 5px;
	}

	.contact-introduction__selected {
		font-size: 1.6rem;
		display: block;
		margin: auto;
		width: auto;
		word-wrap: break-word;
	}

	.contact__work {
		white-space: initial;
		font-size: 2rem;
		margin-top: 15px;
		text-align: center;
	}

}
