@import url("https://use.typekit.net/ykv0mtm.css");

.projects {
	width: 100%;
	will-change: contents;
	min-height: 400px;
	opacity: 0;
	height: 90%;
	margin: 0 auto;
	transform-style: preserve-3d;
	animation: fadeIn 0.6s forwards;
	animation-delay: 0.6s;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	position: relative;
	font-family: ibm-plex-mono, sans-serif;
	z-index: 0;
}
