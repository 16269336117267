.mentions {
	color: snow;
	width: 50%;
	margin-top: 450px;
	font-family: ibm-plex-mono, sans-serif;
	font-weight: 700;
	font-style: normal;
	margin-bottom: 0.5rem;
	padding: 2px;
}

.mentions__codeSymb {
	color: #672ae9;
	font-size: 1.8em;
}

.mentions__comment {
	color: #727474;
	font-size: 1.4em;
	letter-spacing: 1px;
}

.mentions__list h5 {
	color: #672ae9;
}

.acknowledgement {
	color: #727474 !important;
	margin-top: 20px !important;
}

.ouch__link {
	color: #dbdbdb;
	transition: all 0.6s ease;
}

.ouch__link:hover {
	color: #672ae9;
}

@media only screen and (min-width: 375px) and (max-width: 667px) {
	.mentions {
		width: 90%;
	}

	.mentions__list:last-child {
		white-space: initial;
		font-size: 1.5rem;
	}

}