
.zen{
	height: 500px;
	width: 500px;
	position: absolute;
	top: calc(50%);
	right: 0;
	z-index: -1;
}

.zen__link{
	cursor: auto;
}

@media only screen and (min-width: 825px) and (max-width: 915px){
	.zen{
		transition: all 0.8s ease;
		height: 350px;
		width: 350px;
		position: absolute;
	}
}

@media only screen and (min-width: 375px) and (max-width: 667px) {
	.zen{
		transition: all 0.8s ease;
		height: 200px;
		width: 200px;
		position: absolute;
		top: calc(52%);
	}
}

@media only screen and (min-width: 667px) and (max-width: 825px) {
	.zen{
		transition: all 0.8s ease;
		top: calc(52%);
		height: 320px;
		width: 320px;
	}
}

@media screen and (max-width: 375px) {
	.zen{
		transition: all 0.8s ease;
		height: 200px;
		width: 200px;
		top: calc(50%)
	}
}